const { getDocs, query, collection, addDoc, doc, updateDoc, getDoc, orderBy, deleteDoc } = require('firebase/firestore')
const { db } = require('../base')

class LeaveService {
  async getLeaves() {
    const leaves = await getDocs(query(collection(db, 'leaves'), orderBy('from', 'desc')))
    return leaves.docs.map(leave => ({ ...leave.data(), id: leave.id }))
  }

  async getLeave(leaveId) {
    const leaveRef = await doc(db, 'leaves', leaveId)
    const leave = await getDoc(leaveRef)
    return {
      ...leave.data(),
      id: leave.id
    }
  }

  async saveLeave(leave) {
    if (!leave.id) {
      delete leave.id
      await addDoc(collection(db, 'leaves'), leave)
      return
    }

    const leaveRef = await doc(db, 'leaves', leave.id)
    await updateDoc(leaveRef, leave)
  }

  async deleteLeave(leaveId) {
    const taskDocRef = doc(db, 'leaves', leaveId)
    await deleteDoc(taskDocRef)
  }
}

export default new LeaveService()
