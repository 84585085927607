import React from 'react'
import { Link } from 'react-router-dom'
import EmployeeService from '../services/employee.service'
import moment from 'moment'

class EmployeeList extends React.Component {
  state = {
    loading: true,
    allEmployees: [],
    employees: [],
    includeResigned: false,
  }

  async componentDidMount() {
    try {
      const employees = await EmployeeService.getEmployees()

      this.setState({ allEmployees: employees, loading: false }, () => this.filterEmployees())
    } catch (e) {
      console.log(e)
    }
  }

  filterEmployees = () => {
    const includeResigned = this.state.includeResigned;
    const employees = this.state.allEmployees.filter(employee => {
      if (!includeResigned) {
        return !employee.resignDate;
      }

      return true;
    })

    this.setState({ employees, includeResigned: !this.state.includeResigned });
  }

  editEmployee = (employeeId) => {
    window.location.href = '/employees/edit/' + employeeId
  }

  render() {
    return (
        <div className="container container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="m-0">Employees ({this.state.employees.length})</h3>
                <div className="d-flex align-items-center justify-content-center" style={{ gap: 24 }}>
                  <div className="d-flex align-items-center justify-content-center" style={{ gap: 8 }}>
                    <input className="form-check-input" type="checkbox" id="includeResigned"
                           onClick={this.filterEmployees}/>
                    <label className="form-check-label mb-0" htmlFor="includeResigned">
                      Include Resigned
                    </label>
                  </div>
                  <Link to="/employees/add" className="btn btn-primary">Add Employee</Link>
                </div>
              </div>
              <table className="table mt-4 mb-4">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Birth Date</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">PC No</th>
                  <th scope="col">Salary</th>
                </tr>
                </thead>
                <tbody>
                {this.state.loading ? (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <div className="loader">Loading...</div>
                      </td>
                    </tr>
                ) : null}
                {!this.state.loading && this.state.employees.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="text-center"><b>No record found.</b></td>
                    </tr>
                ) : null}
                {!this.state.loading && this.state.employees.map((employee, i) => (
                    <tr key={i} onClick={() => this.editEmployee(employee.id)}
                        style={{ cursor: 'pointer', color: !!employee.resignDate ? 'red' : '' }}>
                      <td>{employee.name}</td>
                      <td>{employee.birthDate ? moment(employee.birthDate).format('DD MMMM YYYY') : 'N/A'}</td>
                      <td>{employee.companyEmail || employee.email}</td>
                      <td>{employee.phone}</td>
                      <td>{employee.pcNumber}</td>
                      <td>&#x20b9; {employee.currentSalary}</td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
    )
  }
}

export default EmployeeList
