const { getDocs, query, collection, addDoc, doc, updateDoc, getDoc, orderBy } = require('firebase/firestore')
const { db } = require('../base')

class EmployeeService {
  async getEmployees() {
    const now = new Date().getTime()
    let employees = await getDocs(query(collection(db, 'employees'), orderBy('name', 'asc')))
    employees = employees.docs.map(employee => ({ ...employee.data(), id: employee.id }))
    employees.forEach(employee => {
      let currentSalary = 0

      for (const salary of (employee.salaries || [])) {
        if (salary.from <= now && (!salary.to || now <= salary.to)) {
          currentSalary = salary.salary
        }
      }

      employee.currentSalary = currentSalary
    })
    return employees
  }

  async getActiveEmployees() {
    const employees = await this.getEmployees();
    return employees.filter(employee => !employee.resignDate);
  }

  async getEmployee(employeeId) {
    const empRef = await doc(db, 'employees', employeeId)
    const employee = await getDoc(empRef)
    return {
      ...employee.data(),
      id: employee.id
    }
  }

  async saveEmployee(employee) {
    if (!employee.id) {
      delete employee.id
      await addDoc(collection(db, 'employees'), employee)
      return
    }

    const empRef = await doc(db, 'employees', employee.id)
    await updateDoc(empRef, employee)
  }
}

export default new EmployeeService()
