import React from 'react'
import Datetime from 'react-datetime'
import * as _ from 'lodash'
import EmployeeService from './../services/employee.service'
import LeaveService from './../services/leave.service'
import * as moment from 'moment'

class LeaveForm extends React.Component {
  state = {
    loading: true,
    id: '',
    employees: [],
    leave: {
      employeeId: '',
      from: null,
      to: null,
      noOfDays: 1,
      notes: ''
    }
  }

  async componentDidMount() {
    const url = window.location.pathname
    const id = url.includes('edit') ? url.substring(url.lastIndexOf('/') + 1, url.length) : null

    try {
      const employees = await EmployeeService.getActiveEmployees()

      this.setState({ employees })
    } catch (e) {
      console.log('Error while fetching employees', e)
    }

    if (id) {
      try {
        const leave = await LeaveService.getLeave(id)

        this.setState({ id, leave })
      } catch (e) {
        console.log(e)
      }
    }

    this.setState({ loading: false })
  }

  onUpdateLeave = (key, value) => {
    const leave = _.cloneDeep(this.state.leave)
    leave[key] = value

    if (key === 'from') {
      leave['to'] = moment(value).endOf('day').valueOf()
    }

    this.setState({ leave })
  }

  saveLeave = async () => {
    try {
      const leaveToSave = _.cloneDeep(this.state.leave)
      leaveToSave.id = this.state.id
      leaveToSave.name = this.state.employees.find(employee => employee.id === leaveToSave.employeeId).name
      console.log(leaveToSave)

      await LeaveService.saveLeave(leaveToSave)
      window.location.href = '/leaves'
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  deleteLeave = async () => {
    if (window.confirm('Are you sure want to delete this leave?') !== true) {
      return
    }

    try {
      await LeaveService.deleteLeave(this.state.id)
      await this.componentDidMount()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    return (
        <div className="container container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="">
                <h3 className="m-0">Add/Edit Leave</h3>
              </div>
              {this.state.loading ? <div className="loader">Loading...</div> : (
                  <div className="mt-4">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Employee</label>
                      <select className="form-select" aria-label="Default select example"
                              defaultValue={this.state.leave?.employeeId}
                              onChange={(e) => this.onUpdateLeave('employeeId', e.target.value)}>
                        <option>--- Select Employee ---</option>
                        {this.state.employees.map((employee, i) => (
                            <option key={i} value={employee.id}>{employee.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="no-of-days" className="form-label">No of Days</label>
                      <input type="text" className="form-control" id="no-of-days" defaultValue={this.state.leave?.noOfDays || 1}
                             onChange={(e) => this.onUpdateLeave('noOfDays', +e.target.value)}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="from-date" className="form-label">From Date</label>
                      <Datetime id="from-date" initialValue={this.state.leave?.from ? new Date(this.state.leave.from) : null}
                                closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                onChange={(date) => this.onUpdateLeave('from', date.valueOf())}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="to-date" className="form-label">To Date</label>
                      <Datetime id="to-date" value={this.state.leave?.to ? new Date(this.state.leave.to) : null}
                                closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                onChange={(date) => this.onUpdateLeave('to', date.valueOf())}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="comment" className="form-label">Comment</label>
                      <textarea className="form-control" id="comment" rows="3"
                                defaultValue={this.state.leave?.notes}
                                onChange={(e) => this.onUpdateLeave('notes', e.target.value)}></textarea>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-primary" onClick={this.saveLeave}>Save</button>
                      <button className="btn btn-secondary ms-2" onClick={() => window.location.href = '/leaves'}>Cancel</button>
                      {this.state.id ? <button className="btn btn-danger float-end" onClick={this.deleteLeave}>Delete</button> : null}
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
    )
  }
}

export default LeaveForm
