import React, { Fragment } from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import UtilsService from '../../services/utils.service'

// const borderColor = '#BFF0FD'
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#BFF0FD',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold'
  },
  headingColumn: {
    width: '30%',
    textAlign: 'left',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8
  },
  column: {
    width: '20%',
    textAlign: 'left',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8
  },
  footerHeadingColumn: {
    width: '30%',
    textAlign: 'left',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
    fontWeight: 'bold'
  },
  footerColumn: {
    width: '20%',
    textAlign: 'left',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
    fontStyle: 'bold'
  }
})

const InvoiceTableRow = ({ salarySlip }) => {
  return (
      <Fragment>
        <>
          <View style={styles.row}>
            <Text style={styles.headingColumn}>Salary</Text>
            <Text style={styles.column}>{UtilsService.formatCurrency(salarySlip.salary)}</Text>
            <Text style={styles.headingColumn}>Paid Leave(s)</Text>
            <Text style={styles.column}>{salarySlip.paidLeaves}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.headingColumn}>Bonus</Text>
            <Text style={styles.column}>{UtilsService.formatCurrency(salarySlip.bonus)}</Text>
            <Text style={styles.headingColumn}>UnPaid Leave(s)</Text>
            <Text style={styles.column}>{salarySlip.unPaidLeaves}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.headingColumn}>Reimbursement</Text>
            <Text style={styles.column}>{UtilsService.formatCurrency(salarySlip.cashOut)}</Text>
            <Text style={styles.headingColumn}></Text>
            <Text style={styles.column}></Text>
          </View>
          {Array.from(Array(4).keys()).map(num => (
              <View style={styles.row}>
                <Text style={styles.headingColumn}> </Text>
                <Text style={styles.column}></Text>
                <Text style={styles.headingColumn}> </Text>
                <Text style={styles.column}></Text>
              </View>
          ))}
          <View style={styles.row}>
            <Text style={styles.headingColumn}>Total Salary</Text>
            <Text style={styles.column}>{UtilsService.formatCurrency(salarySlip.salary + salarySlip.bonus + salarySlip.cashOut)}</Text>
            <Text style={styles.headingColumn}>Total Deduction</Text>
            <Text style={styles.column}>{UtilsService.formatCurrency(salarySlip.leaveCut)}</Text>
          </View>
          {Array.from(Array(3).keys()).map(num => (
              <View style={styles.row}>
                <Text style={styles.headingColumn}></Text>
                <Text style={styles.column}></Text>
                <Text style={styles.headingColumn}></Text>
                <Text style={styles.column}></Text>
              </View>
          ))}
          <View style={styles.row}>
            <Text style={styles.headingColumn}>Net Salary</Text>
            <Text style={styles.column}>{UtilsService.formatCurrency(salarySlip.nettSalaryCalc + salarySlip.bonus + salarySlip.cashOut)}</Text>
            <Text style={styles.headingColumn}></Text>
            <Text style={styles.column}></Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.headingColumn}>Rounding</Text>
            <Text style={styles.column}>{UtilsService.formatCurrency(salarySlip.nettSalary - salarySlip.nettSalaryCalc - salarySlip.bonus - salarySlip.cashOut)}</Text>
            <Text style={styles.headingColumn}></Text>
            <Text style={styles.column}></Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.headingColumn}></Text>
            <Text style={styles.column}></Text>
            <Text style={styles.headingColumn}></Text>
            <Text style={styles.column}></Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.footerHeadingColumn}>Gross Salary</Text>
            <Text style={styles.footerColumn}>{UtilsService.formatCurrency(salarySlip.nettSalary)}</Text>
            <Text style={styles.headingColumn}></Text>
            <Text style={styles.column}></Text>
          </View>
        </>
      </Fragment>
  )
}

export default InvoiceTableRow
