import React from 'react'
import * as _ from 'lodash'
import SettingService from './../services/setting.service'

class SettingForm extends React.Component {
  state = {
    loading: true,
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    settings: {
      id: null,
      totalPaidLeaves: null,
      totalDaysInMonth: null,
      cashOutMonth: null,
      salaryDate: null
    }
  }

  async componentDidMount() {
    try {
      const settings = await SettingService.getSetting()

      if (settings) {
        this.setState({ settings })
      }
    } catch (e) {
      console.log(e)
    }

    this.setState({ loading: false })
  }

  onUpdateSettings = (key, value) => {
    const settings = _.cloneDeep(this.state.settings)
    settings[key] = value
    this.setState({ settings })
  }

  saveSettings = async () => {
    try {
      const settingsToSave = _.cloneDeep(this.state.settings)
      await SettingService.saveSettings(settingsToSave)
      window.location.href = '/settings'
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  render() {
    return (
        <div className="container container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="">
                <h3 className="m-0">Settings</h3>
              </div>
              {this.state.loading ? <div className="loader">Loading...</div> : (
                  <div className="mt-4">
                    <div className="mb-3">
                      <label htmlFor="total-paid-leaves" className="form-label">Total Paid Leaves</label>
                      <input type="text" className="form-control" id="total-paid-leaves" defaultValue={this.state.settings?.totalPaidLeaves}
                             onChange={(e) => this.onUpdateSettings('totalPaidLeaves', e.target.value)}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="total-days-per-month" className="form-label">Total Days per Moneth</label>
                      <input type="text" className="form-control" id="total-days-per-month" defaultValue={this.state.settings?.totalDaysInMonth}
                             onChange={(e) => this.onUpdateSettings('totalDaysInMonth', e.target.value)}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="cash-out-month" className="form-label">Cash-out Month</label>
                      <select className="form-select" aria-label="Default select example"
                              defaultValue={this.state.settings?.cashOutMonth}
                              onChange={(e) => this.onUpdateSettings('cashOutMonth', e.target.value)}>
                        <option>--- Select Month ---</option>
                        {this.state.months.map((month, i) => (
                            <option key={i} value={month}>{month}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-primary" onClick={this.saveSettings}>Save</button>
                      <button className="btn btn-secondary ms-2" onClick={() => window.location.href = '/'}>Cancel</button>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
    )
  }
}

export default SettingForm
