import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({

  titleContainer: {
    flexDirection: 'column',
    marginTop: 16
  },
  reportTitle: {
    color: '#329599',
    letterSpacing: 1,
    fontSize: 26,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  subTitle: {
    letterSpacing: 1,
    fontSize: 16,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: 16,
    marginBottom: 32
  }
})

const InvoiceTitle = ({ title, subTitle }) => (
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>{title}</Text>
      <Text style={styles.subTitle}>{subTitle}</Text>
    </View>
)

export default InvoiceTitle
