const { getDocs, query, collection, addDoc, doc, updateDoc } = require('firebase/firestore')
const { db } = require('../base')

class SettingService {
  async getSetting() {
    let leaves = await getDocs(query(collection(db, 'settings')))
    leaves = leaves.docs.map(leave => ({ ...leave.data(), id: leave.id }))
    return leaves.length > 0 ? leaves[0] : null
  }

  async saveSettings(setting) {
    if (!setting.id) {
      delete setting.id
      await addDoc(collection(db, 'settings'), setting)
      return
    }

    const leaveRef = await doc(db, 'settings', setting.id)
    await updateDoc(leaveRef, setting)
  }
}

export default new SettingService()
