import React from 'react'
import { Link } from 'react-router-dom'
import LeaveService from '../services/leave.service'
import * as moment from 'moment'
import Datetime from 'react-datetime'
import EmployeeService from '../services/employee.service'

class LeaveList extends React.Component {
  state = {
    loading: true,
    employees: [],
    allLeaves: [],
    leaves: [],
    selectedMonth: moment().startOf('month').valueOf(),
    selectedEmployee: ''
  }

  async componentDidMount() {
    try {
      const employees = await EmployeeService.getActiveEmployees()

      this.setState({ employees })
    } catch (e) {
      console.log('Error while fetching employees', e)
    }

    try {
      const leaves = await LeaveService.getLeaves()

      leaves.forEach(leave => {
        leave.fromStr = moment(leave.from).format('DD-MM-YYYY')
        leave.toStr = moment(leave.to).format('DD-MM-YYYY')
      })

      this.setState({ allLeaves: [...leaves], loading: false }, () => this.filterLeaves())
    } catch (e) {
      console.log(e)
    }
  }

  onSelectedMonthChange = (date) => {
    this.setState({ selectedMonth: date ? date.startOf('month').valueOf() : '' }, () => this.filterLeaves())
  }

  onEmployeeChange = (e) => {
    this.setState({ selectedEmployee: e.target.value }, () => this.filterLeaves())
  }

  filterLeaves = () => {
    const allLeaves = [ ...this.state.allLeaves ]
    const startDate = moment(this.state.selectedMonth).startOf('months').valueOf()
    const endDate = moment(this.state.selectedMonth).endOf('months').valueOf()
    const leaves = allLeaves.filter(leave => {
      if (this.state.selectedEmployee && leave.employeeId !== this.state.selectedEmployee) {
        return false
      }

      if (this.state.selectedMonth
          && (leave.to <= startDate || endDate <= leave.from)) {
        return false
      }

      return true
    })
    this.setState({ leaves })
  }

  editLeave = (leaveId) => {
    window.location.href = '/leaves/edit/' + leaveId
  }

  deleteLeave = async (event, leaveId) => {
    event.stopPropagation()

    if (window.confirm('Are you sure want to delete this leave?') !== true) {
      return
    }

    try {
      await LeaveService.deleteLeave(leaveId)
      await this.componentDidMount()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    return (
        <div className="container container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="m-0">Leaves</h3>
                <Link to="/leaves/add" className="btn btn-primary">Add Leave</Link>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <Datetime id="filter-date" value={this.state.selectedMonth ? new Date(this.state.selectedMonth) : null}
                          closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                          inputProps={{ 'placeholder': 'Select Month' }}
                          onChange={this.onSelectedMonthChange}/>

                <div className="ms-3">
                  <select className="form-select"
                          onChange={this.onEmployeeChange}>
                    <option value=''>--- Select Employee ---</option>
                    {this.state.employees.map((employee, i) => (
                        <option key={i} value={employee.id}>{employee.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <table className="table mt-4 mb-4">
                <colgroup>
                  <col width="25%"/>
                  <col width="10%"/>
                  <col width="10%"/>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="80px"/>
                </colgroup>
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">From Date</th>
                  <th scope="col">To Date</th>
                  <th scope="col">No of Days</th>
                  <th scope="col">Note</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {this.state.loading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <div className="loader">Loading...</div>
                      </td>
                    </tr>
                ) : null}
                {!this.state.loading && this.state.leaves.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center"><b>No record found.</b></td>
                    </tr>
                ) : null}
                {!this.state.loading && this.state.leaves.map((leave, i) => (
                    <tr key={i} onClick={() => this.editLeave(leave.id)} style={{ cursor: 'pointer' }}>
                      <td>{leave.name}</td>
                      <td>{leave.fromStr}</td>
                      <td>{leave.toStr}</td>
                      <td>{leave.noOfDays}</td>
                      <td>{leave.notes}</td>
                      <td>
                        <button className="btn btn-danger" onClick={(e) => this.deleteLeave(e, leave.id)}>X</button>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
    )
  }
}

export default LeaveList
