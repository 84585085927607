import React from 'react'
import Datetime from 'react-datetime'
import * as _ from 'lodash'
import EmployeeService from './../services/employee.service'

class EmployeeForm extends React.Component {
  state = {
    loading: true,
    id: '',
    name: '',
    email: '',
    companyEmail: '',
    phone: '',
    birthDate: null,
    joiningDate: null,
    nextReviewDate: null,
    resignDate: null,
    address: '',
    contract: {
      from: null,
      to: null,
      documents: ''
    },
    pcNumber: '',
    comment: '',
    salaries: [
      {
        from: null,
        to: null,
        salary: 0
      }
    ]
  }

  async componentDidMount() {
    const url = window.location.pathname
    const id = url.includes('edit') ? url.substring(url.lastIndexOf('/') + 1, url.length) : null

    if (id) {
      try {
        const employee = await EmployeeService.getEmployee(id)

        this.setState({ ...employee })
        console.log(employee, id, { ...employee })
      } catch (e) {
        console.log(e)
      }
    }

    this.setState({ loading: false })
  }

  addSalary = () => {
    const salaries = _.cloneDeep(this.state.salaries)
    salaries.push({
      from: null,
      to: null,
      salary: 0
    })
    this.setState({ salaries })
  }

  onUpdateSalary = (index, key, value) => {
    const salaries = _.cloneDeep(this.state.salaries)
    salaries[index][key] = value
    this.setState({ salaries })
  }

  removeSalary = (index) => {
    const salaries = _.cloneDeep(this.state.salaries)
    salaries.splice(index, 1)
    this.setState({ salaries })
  }

  onUpdateContract = (key, value) => {
    const contract = _.cloneDeep(this.state.contract)
    contract[key] = value
    this.setState({ contract })
  }

  saveEmployee = async () => {
    try {
      const employeeToSave = _.cloneDeep(this.state)
      delete employeeToSave.loading
      await EmployeeService.saveEmployee(employeeToSave)
      window.location.href = '/employees'
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  render() {
    return (
        <div className="container container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="">
                <h3 className="m-0">Add/Edit Employee</h3>
              </div>
              {this.state.loading ? <div className="loader">Loading...</div> : (
                  <div className="mt-4">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input type="text" className="form-control" id="name" defaultValue={this.state.name}
                             onChange={(e) => this.setState({ name: e.target.value })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="email" className="form-control" id="email" defaultValue={this.state.email}
                             onChange={(e) => this.setState({ email: e.target.value })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Company Email</label>
                      <input type="email" className="form-control" id="company-email" defaultValue={this.state.companyEmail}
                             onChange={(e) => this.setState({ companyEmail: e.target.value })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">Phone</label>
                      <input type="text" className="form-control" id="phone" defaultValue={this.state.phone}
                             onChange={(e) => this.setState({ phone: e.target.value })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="address" className="form-label">Address</label>
                      <input type="text" className="form-control" id="address" defaultValue={this.state.address}
                             onChange={(e) => this.setState({ address: e.target.value })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="joined-date" className="form-label">Birth Date</label>
                      <Datetime id="joined-date" initialValue={this.state.birthDate ? new Date(this.state.birthDate) : null}
                                closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                onChange={(date) => this.setState({ birthDate: date.valueOf() })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="joined-date" className="form-label">Joined Date</label>
                      <Datetime id="joined-date" initialValue={this.state.joiningDate ? new Date(this.state.joiningDate) : null}
                                closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                onChange={(date) => this.setState({ joiningDate: date.valueOf() })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="next-review-date" className="form-label">Next Review Date</label>
                      <Datetime id="next-review-date" initialValue={this.state.nextReviewDate ? new Date(this.state.nextReviewDate) : null}
                                closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                onChange={(date) => this.setState({ nextReviewDate: date.valueOf() })}/>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>Salary</h4>
                        <button className="btn btn-outline-primary" onClick={this.addSalary}>Add</button>
                      </div>
                      <table className="table">
                        <thead>
                        <tr>
                          <th scope="col">From</th>
                          <th scope="col">To</th>
                          <th scope="col">Salary</th>
                          <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.salaries.map((salary, i) => (
                            <tr key={i}>
                              <td>
                                <Datetime initialValue={salary.from ? new Date(salary.from) : null}
                                          closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                          onChange={(date) => this.onUpdateSalary(i, 'from', date.valueOf())}/>
                              </td>
                              <td>
                                <Datetime initialValue={salary.to ? new Date(salary.to) : null}
                                          closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                          onChange={(date) => this.onUpdateSalary(i, 'to', date.valueOf())}/>
                              </td>
                              <td>
                                <div className="input-group mb-3">
                                  <span className="input-group-text" id="basic-addon1">&#x20b9;</span>
                                  <input type="text" className="form-control" id={'salary' + i} defaultValue={salary.salary}
                                         onChange={(e) => this.onUpdateSalary(i, 'salary', +e.target.value)}/>
                                </div>
                              </td>
                              <td>
                                <button className="btn btn-outline-danger" onClick={(e) => this.removeSalary(i)}>X</button>
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pc-number" className="form-label">PC Number</label>
                      <input type="text" className="form-control" id="pc-number" defaultValue={this.state.pcNumber}
                             onChange={(e) => this.setState({ pcNumber: e.target.value })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="contract-start-date" className="form-label">Contract Start Date</label>
                      <Datetime id="contract-start-date" initialValue={this.state.contract?.from ? new Date(this.state.contract.from) : null}
                                closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                onChange={(date) => this.onUpdateContract('from', date.valueOf())}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="contract-end-date" className="form-label">Contract End Date</label>
                      <Datetime id="contract-end-date" initialValue={this.state.contract?.to ? new Date(this.state.contract.to) : null}
                                closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                onChange={(date) => this.onUpdateContract('to', date.valueOf())}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="contract-documents" className="form-label">Contract Document</label>
                      <input type="text" className="form-control" id="contract-documents" defaultValue={this.state.contract?.documents}
                             onChange={(e) => this.onUpdateContract('documents', e.target.value)}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="next-review-date" className="form-label">Resign Date</label>
                      <Datetime id="next-review-date" initialValue={this.state.resignDate ? new Date(this.state.resignDate) : null}
                                closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                onChange={(date) => this.setState({ resignDate: date.valueOf() })}/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="comment" className="form-label">Comment</label>
                      <textarea className="form-control" id="comment" rows="3"
                                defaultValue={this.state.comment}
                                onChange={(e) => this.setState({ comment: e.target.value })}></textarea>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-primary" onClick={this.saveEmployee}>Save</button>
                      <button className="btn btn-secondary ms-2" onClick={() => window.location.href = '/employees'}>Cancel</button>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
    )
  }
}

export default EmployeeForm
