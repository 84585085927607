const { getDocs, query, collection, addDoc, doc, updateDoc, where } = require('firebase/firestore')
const { db } = require('../base')

class SalarySlipService {
  async getSalarySlips(month) {
    let salarySlips = await getDocs(query(collection(db, 'salary-slips'), where('month', '==', month)))
    salarySlips = salarySlips.docs.map(salarySlip => ({ ...salarySlip.data(), id: salarySlip.id }))
    return salarySlips
  }

  async saveSalarySlip(salarySlip) {
    if (!salarySlip.id) {
      delete salarySlip.id
      await addDoc(collection(db, 'salary-slips'), salarySlip)
      return
    }

    const salarySlipRef = await doc(db, 'salary-slips', salarySlip.id)
    await updateDoc(salarySlipRef, salarySlip)
  }
}

export default new SalarySlipService()
