import React from 'react'
import SettingService from '../services/setting.service'
import EmployeeService from '../services/employee.service'
import LeaveService from '../services/leave.service'
import * as _ from 'lodash'
import * as moment from 'moment'
import Datetime from 'react-datetime'
import SalarySlipService from '../services/salary-slip.service'
import UtilsService from '../services/utils.service'
// import SalarySlip from '../components/SalarySlip'
import Invoice from '../components/SalarySlip/Invoice'
import { PDFDownloadLink } from '@react-pdf/renderer'

class SalaryReport extends React.Component {
  state = {
    loading: true,
    salaryMonths: {},
    salarySlips: [],
    reportData: [],
    selectedMonth: moment().startOf('month').valueOf(),
    pdfData: null,
  }

  constructor(props) {
    super(props)
    this.modalBtn = React.createRef()
  }

  async componentDidMount() {
    const currentMonth = moment().startOf('month')
    const previousMonth = currentMonth.clone().subtract(1, 'month')
    const salaryMonths = await UtilsService.getSalaryMonth()

    if (!salaryMonths || !salaryMonths[previousMonth.format('YYYY-MM-DD')]) {
      this.setState({ salaryMonths, selectedMonth: previousMonth.valueOf() })
    }

    await this.init()
  }

  init = async () => {
    try {
      this.setState({ loading: true })
      const employees = await EmployeeService.getActiveEmployees()
      const currentMonth = moment(this.state.selectedMonth)
      const startOfMonth = currentMonth.valueOf()
      const endOfMonth = moment(currentMonth).endOf('month').valueOf()
      const salarySlips = await SalarySlipService.getSalarySlips(currentMonth.format('YYYY-DD-MM'))
      const reportData = []
      const settings = await SettingService.getSetting()
      const leaves = await LeaveService.getLeaves()
      const groupedLeaves = _.groupBy(leaves, leave => leave.employeeId)

      for (const employee of employees) {
        const salary = this.getEmployeeSalary(employee, startOfMonth)
        const matchingSalarySlip = salarySlips.find(s => s.employeeId === employee.id)
        const dailySalary = Math.round(salary / settings.totalDaysInMonth)
        const empLeaves = (groupedLeaves[employee.id] || []).filter(leave => {
          return leave.from >= startOfMonth && leave.from <= endOfMonth
        })
        const totalLeaves = empLeaves.reduce((total, leave) => total += leave.noOfDays, 0)
        const paidLeaves = totalLeaves > 0 ? 1 : 0
        const unPaidLeaves = totalLeaves > 0 ? (totalLeaves - paidLeaves) : 0
        const leaveCut = (unPaidLeaves > 0 ? (unPaidLeaves * dailySalary) : 0)
        const bonus = matchingSalarySlip?.bonus ||0
        const cashOut = matchingSalarySlip?.cashOut ||0
        const nettSalaryCalc = salary - leaveCut
        const month = matchingSalarySlip?.month || currentMonth.format('YYYY-DD-MM')

        reportData.push({
          id: matchingSalarySlip?.id,
          month,
          monthInTimestamp: moment(month, 'YYYY-DD-MM').valueOf(),
          employeeId: employee.id,
          name: employee.name,
          salary,
          dailySalary,
          totalLeaves,
          paidLeaves,
          unPaidLeaves,
          leaveCut,
          cashOut,
          bonus,
          nettSalaryCalc,
          nettSalary: matchingSalarySlip?.nettSalary || nettSalaryCalc,
          paidDate: matchingSalarySlip?.paidDate || '',
          paidBy: matchingSalarySlip?.paidBy || '',
          paidFrom: matchingSalarySlip?.paidFrom || ''
        })
      }

      this.setState({ salarySlips: reportData, loading: false })
    } catch (e) {
      console.log(e)
    }

    this.setState({ loading: false })
  }

  getEmployeeSalary = (employee, month) => {
    let currentSalary = 0

    for (const salary of (employee.salaries || [])) {
      if (salary.from <= month && (!salary.to || month <= salary.to)) {
        currentSalary = salary.salary
      }
    }

    return currentSalary
  }

  onFilter = async (date) => {
    this.setState({ selectedMonth: date.startOf('month').valueOf() })
    await this.init()
  }

  updateValue = (i, key, value, isReCalc = false) => {
    const salarySlips = [ ...this.state.salarySlips ]
    salarySlips[i][key] = value

    this.setState({ salarySlips }, () => {
      if (isReCalc) {
        salarySlips[i].nettSalary = salarySlips[i].nettSalaryCalc + salarySlips[i].cashOut + salarySlips[i].bonus
        this.setState({ salarySlips })
      }
    })
  }

  getTotalSalary = () => {
    let total = 0
    for (let s of (this.state.salarySlips || [])) {
      total += s.nettSalary
    }
    return total
  }

  saveSalarySlips = async () => {
    const salarySlips = [ ...this.state.salarySlips ]

    for (let salarySlip of salarySlips) {
      await SalarySlipService.saveSalarySlip(salarySlip)
    }

    window.location.reload();
  }

  markAsPaidMonth = async () => {
    await this.saveSalarySlips()
    const salaryMonths = { ...this.state.salaryMonths }
    salaryMonths[moment(this.state.selectedMonth).format('YYYY-MM-DD')] = true
    await UtilsService.saveSalaryMonth(salaryMonths)

    window.location.reload();
  }

  editEmployee = (employeeId) => {
    window.location.href = '/employees/edit/' + employeeId
  }

  downloadReport = (report) => {
    this.setState({ pdfData: report })
    this.modalBtn.current.click();
  }

  render() {
    return (
        <div className="p-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="m-0">Salary Report - {moment(this.state.selectedMonth).add(1, 'month').format('MMM-YYYY')}</h3>
                <div className="d-flex align-items-center justify-content-center">
                  <Datetime id="filter-date" value={this.state.selectedMonth ? new Date(this.state.selectedMonth) : null}
                            closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                            onChange={this.onFilter}/>
                  <button className="btn btn-primary ms-2" onClick={this.saveSalarySlips}>Save</button>
                  <button className="btn btn-danger ms-2" onClick={this.markAsPaidMonth}>Mark As Paid</button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table mt-4 mb-4">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Salary</th>
                    <th scope="col">Daily Salary</th>
                    <th scope="col">Total Leaves</th>
                    <th scope="col">Paid Leaves</th>
                    <th scope="col">Unpaid Leaves</th>
                    <th scope="col">Leave Cut</th>
                    <th scope="col">Cash out</th>
                    <th scope="col">Bonus</th>
                    <th scope="col">Nett Salary Calc</th>
                    <th scope="col">Nett Salary</th>
                    <th scope="col">Paid Date</th>
                    <th scope="col">Paid By</th>
                    <th scope="col">Paid From</th>
                    <th scope="col">Download</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.loading ? (
                      <tr>
                        <td colSpan="13" className="text-center">
                          <div className="loader">Loading...</div>
                        </td>
                      </tr>
                  ) : null}
                  {!this.state.loading && this.state.salarySlips.map((employee, i) => (
                      <tr key={i} className={employee.paidDate || employee.nettSalary === 0 ? 'salary-paid' : ''}>
                        <td onClick={() => this.editEmployee(employee.employeeId)} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                          {employee.name}
                        </td>
                        <td className="no-wrap">&#x20b9; {employee.salary}</td>
                        <td className="no-wrap">&#x20b9; {employee.dailySalary}</td>
                        <td>{employee.totalLeaves}</td>
                        <td>{employee.paidLeaves}</td>
                        <td>{employee.unPaidLeaves > 0 ? <b style={{ color: 'red' }}>{employee.unPaidLeaves}</b> : 0}</td>
                        <td>{employee.leaveCut > 0 ? <b style={{ color: 'red' }}>{employee.leaveCut}</b> : 0}</td>
                        <td>
                          <div className="input-group mb-3">
                            <span className="input-group-text">&#x20b9;</span>
                            <input type="text" className="form-control max-width-100" defaultValue={employee.cashOut}
                                   onChange={(e) => this.updateValue(i, 'cashOut', +e.target.value, true)}/>
                          </div>
                        </td>
                        <td>
                          <div className="input-group mb-3">
                            <span className="input-group-text">&#x20b9;</span>
                            <input type="text" className="form-control max-width-100" defaultValue={employee.bonus}
                                   onChange={(e) => this.updateValue(i, 'bonus', +e.target.value, true)}/>
                          </div>
                        </td>
                        <td>&#x20b9; {employee.nettSalaryCalc}</td>
                        <td>
                          <div className="input-group mb-3">
                            <span className="input-group-text">&#x20b9;</span>
                            <input type="text" className="form-control max-width-100" value={employee.nettSalary}
                                   onChange={(e) => this.updateValue(i, 'nettSalary', +e.target.value)}/>
                          </div>
                        </td>
                        <td>
                          <Datetime initialValue={employee.paidDate ? new Date(employee.paidDate) : null}
                                    closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                                    onChange={(date) => this.updateValue(i, 'paidDate', date.valueOf())}
                                    inputProps={{ className: 'max-width-120' }}/>
                        </td>
                        <td>
                          <input type="text" className="form-control" defaultValue={employee.paidBy}
                                 onChange={(e) => this.updateValue(i, 'paidBy', e.target.value)}/>
                        </td>
                        <td>
                          <input type="text" className="form-control" defaultValue={employee.paidFrom}
                                 onChange={(e) => this.updateValue(i, 'paidFrom', e.target.value)}/>
                        </td>
                        <td>
                          <PDFDownloadLink document={<Invoice salarySlip={employee} />} fileName={`${employee.name}-${moment(employee.month).format('MMM YYYY')}.pdf`}>
                            {({ blob, url, loading, error }) => (loading ? 'Download' : 'Download')}
                          </PDFDownloadLink>
                          {/*<button type="button" className="btn btn-primary" onClick={() => this.downloadReport(employee)}>Download</button>*/}
                        </td>
                      </tr>
                  ))}
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colSpan={10}></td>
                    <td><b>&#x20b9; {this.getTotalSalary()}</b></td>
                    <td colSpan={2}></td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          {/*<button ref={this.modalBtn} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">Open</button>*/}
          {/*<SalarySlip salarySlip={this.state.pdfData}/>*/}
        </div>
    )
  }
}

export default SalaryReport
