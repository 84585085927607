import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    marginTop: 36
  },
  invoiceNoContainer: {
    flexDirection: 'row',
    gap: 8
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold'
  },
  label: {
    fontSize: 12,
    fontStyle: 'bold'
  }
})

const InvoiceNo = ({ date, name }) => (
    <View style={styles.container}>
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>Date: </Text>
        <Text style={styles.invoiceDate}>{date}</Text>
      </View>
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>Employee Name: </Text>
        <Text style={styles.invoiceDate}>{name}</Text>
      </View>
      {/*<View style={styles.invoiceNoContainer}>*/}
      {/*  <Text style={styles.label}>Designation: </Text>*/}
      {/*  <Text style={styles.invoiceDate}>Junior Developer</Text>*/}
      {/*</View>*/}
    </View>
)

export default InvoiceNo
