import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "react-datetime/css/react-datetime.css";
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import Layout from './Layout'
import Home from './pages/Home'
import EmployeeList from './pages/EmployeeList'
import EmployeeForm from './pages/EmployeeForm'
import LeaveList from './pages/LeaveList'
import LeaveForm from './pages/LeaveForm'
import SettingForm from './pages/SettingForm'
import SalaryReport from './pages/SalaryReport'
import AnnualLeaveReport from './pages/AnnualLeaveReport'

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path='/' element={<PrivateRoute/>}>
            <Route exact path='/' element={<Layout/>}>
              <Route exact path='/' element={<Home/>}/>
              <Route exact path='/employees' element={<EmployeeList/>}/>
              <Route exact path='/employees/add' element={<EmployeeForm/>}/>
              <Route exact path='/employees/edit/:id' element={<EmployeeForm/>}/>
              <Route exact path='/leaves' element={<LeaveList/>}/>
              <Route exact path='/leaves/add' element={<LeaveForm/>}/>
              <Route exact path='/leaves/edit/:id' element={<LeaveForm/>}/>
              <Route exact path='/salary-report' element={<SalaryReport/>}/>
              <Route exact path='/annual-leave-report' element={<AnnualLeaveReport/>}/>
              <Route exact path='/settings' element={<SettingForm/>}/>
            </Route>
          </Route>
          <Route exact path="/login" element={<Login/>} />
          <Route path="*" element={<Login/>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
