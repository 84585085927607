import React from 'react'
import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer'
import InvoiceTitle from './InvoiceTitle'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import moment from 'moment'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

const Invoice = ({ salarySlip }) => {
  const month = moment(salarySlip.monthInTimestamp).format('MMMM YYYY');
  const date = moment(salarySlip.paidDate).format('DD-MM-YYYY')

  return (
      <Document>
        <Page size="A4" style={styles.page}>
          <InvoiceTitle title="Flex Team" subTitle={`Salary Slip for ${month}`}/>
          <InvoiceNo date={date} name={salarySlip.name}/>
          <InvoiceItemsTable salarySlip={salarySlip}/>
        </Page>
      </Document>
  )
}

export default Invoice
