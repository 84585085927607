import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

const borderColor = '#329599'
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#329599',
    backgroundColor: '#7db3b4',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1
  },
  title: {
    width: '50%',
    borderRightColor: borderColor,
    borderRightWidth: 1
  }
})

const InvoiceTableHeader = () => (
    <View style={styles.container}>
      <Text style={styles.title}>Earnings</Text>
      <Text style={styles.title}>Deductions</Text>
    </View>
)

export default InvoiceTableHeader
