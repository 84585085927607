import React from 'react'
import EmployeeService from '../services/employee.service'
import * as moment from 'moment'
import * as _ from 'lodash'

class Home extends React.Component {
  state = {
    loading: true,
    totalSalary: 0,
    totalEmployees: 0,
    employees: [],
    upcomingReviews: [],
    upcomingBirthdays: [],
  }

  async componentDidMount() {
    try {
      const employees = await EmployeeService.getActiveEmployees()
      const totalEmployees = employees.length
      const totalSalary = employees.reduce((total, employee) => total += employee.currentSalary, 0)
      const now = moment().valueOf()
      const nextTwoMonth = moment().add(2, 'months').valueOf()
      const nextTwoWeeks = moment().add(2, 'weeks')
      let upcomingReviews = employees.filter(employee => employee.nextReviewDate && now <= employee.nextReviewDate && employee.nextReviewDate <= nextTwoMonth)
      upcomingReviews = _.sortBy(upcomingReviews, (e) => e.nextReviewDate)
      let upcomingBirthdays = employees.filter(employee => {
        return employee.birthDate && moment(employee.birthDate).set('year', moment().get('year')).isBetween(moment(), nextTwoWeeks)
      })
      upcomingBirthdays = _.sortBy(upcomingBirthdays, (e) => e.birthDate)

      this.setState({ totalEmployees, totalSalary, employees, upcomingReviews, upcomingBirthdays })
    } catch (e) {
      console.log(e)
    }

    this.setState({ loading: false })
  }

  editEmployee = (employeeId) => {
    window.location.href = '/employees/edit/' + employeeId
  }

  getUpcomingReviewSection = () => {
    if (this.state.loading || this.state.upcomingReviews.length === 0) {
      return
    }

    return <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="">
              <h3 className="m-0">Upcoming Reviews</h3>
            </div>
            <table className="table mt-4 mb-4">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Review Date</th>
                <th scope="col">Salary</th>
              </tr>
              </thead>
              <tbody>
              {this.state.upcomingReviews.map((employee, i) => (
                  <tr key={i} onClick={() => this.editEmployee(employee.id)} style={{ cursor: 'pointer' }}>
                    <td>{employee.name}</td>
                    <td>{moment(employee.nextReviewDate).format('DD-MM-YYYY')}</td>
                    <td>&#x20b9; {employee.currentSalary}</td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  }

  getUpcomingBirthdaysSection = () => {
    if (this.state.loading || this.state.upcomingBirthdays.length === 0) {
      return
    }

    return <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="">
              <h3 className="m-0">Upcoming Birthdays</h3>
            </div>
            <table className="table mt-4 mb-4">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Birth Date</th>
              </tr>
              </thead>
              <tbody>
              {this.state.upcomingBirthdays.map((employee, i) => (
                  <tr key={i} onClick={() => this.editEmployee(employee.id)} style={{ cursor: 'pointer' }}>
                    <td>{employee.name}</td>
                    <td>{moment(employee.birthDate).format('DD-MM-YYYY')}</td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  }

  render() {
    return (
        <div className="container container-fluid">
          <div className="row">
            <div className="col-4">
              <div className="card">
                <div className="card-body d-flex flex-column align-items-center">
                  <h4 className="mb-4">Total Employees</h4>
                  {this.state.loading ? <div className="loader">Loading...</div> : <h1>{this.state.totalEmployees}</h1>}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card">
                <div className="card-body d-flex flex-column align-items-center">
                  <h4 className="mb-4">Total Salary</h4>
                  {this.state.loading ? <div className="loader">Loading...</div> : <h1>{this.state.totalSalary}</h1>}
                </div>
              </div>
            </div>
          </div>
          {this.getUpcomingReviewSection()}
          {this.getUpcomingBirthdaysSection()}
        </div>
    )
  }
}

export default Home
