import React from 'react'
import SettingService from '../services/setting.service'
import EmployeeService from '../services/employee.service'
import LeaveService from '../services/leave.service'
import * as _ from 'lodash'
import * as moment from 'moment'
import Datetime from 'react-datetime'

class AnnualLeaveReport extends React.Component {
  state = {
    loading: true,
    reportData: [],
    selectedYear: moment().startOf('year').valueOf()
  }

  async componentDidMount() {
    if (moment().get('month') === 0) {
      this.setState({ selectedYear: moment().startOf('year').subtract(1, 'year') })
    }

    await this.init()
  }

  init = async () => {
    try {
      this.setState({ loading: true })
      const employees = await EmployeeService.getActiveEmployees()
      const settings = await SettingService.getSetting()
      const leaves = await LeaveService.getLeaves()
      const currentYear = moment(this.state.selectedYear)
      const startOfYear = currentYear.startOf('year').valueOf()
      const endOfYear = moment(currentYear).endOf('year').valueOf()
      const groupedLeaves = _.groupBy(leaves, leave => leave.employeeId)
      const reportData = []

      for (const employee of employees) {
        const salary = this.getEmployeeSalary(employee, moment(endOfYear).startOf('month'))
        const dailySalary = Math.round(salary / settings.totalDaysInMonth)
        const empLeaves = (groupedLeaves[employee.id] || []).filter(leave => {
          return leave.from >= startOfYear && leave.from <= endOfYear
        })
        const totalLeaves = empLeaves.reduce((total, leave) => total += leave.noOfDays, 0)
        let startDate = moment(startOfYear)
        let endDate = moment(endOfYear)

        if (moment(employee.joiningDate).valueOf() > endOfYear) {
          continue
        }

        if (moment(employee.joiningDate).valueOf() > startOfYear) {
          startDate = moment(employee.joiningDate).startOf('month')
        }

        const totalPaidLeaves = endDate.diff(startDate, 'month') + 1
        let usedPaidLeaves = 0

        for (let i = 0; i < 12; i++) {
          if (empLeaves.find(leave => moment(leave.from).get('month') === i)) {
            usedPaidLeaves++
          }
        }

        const remainingPaidLeaves = totalPaidLeaves - usedPaidLeaves
        const amount = remainingPaidLeaves * dailySalary

        reportData.push({
          employeeId: employee.id,
          name: employee.name,
          salary,
          dailySalary,
          totalPaidLeaves,
          totalLeaves,
          usedPaidLeaves,
          remainingPaidLeaves,
          amount
        })
      }

      this.setState({ reportData, loading: false })
    } catch (e) {
      console.log(e)
    }

    this.setState({ loading: false })
  }

  getEmployeeSalary = (employee, month) => {
    let currentSalary = 0

    for (const salary of (employee.salaries || [])) {
      if (salary.from <= month && (!salary.to || month <= salary.to)) {
        currentSalary = salary.salary
      }
    }

    return currentSalary
  }

  onFilter = async (date) => {
    this.setState({ selectedYear: date.startOf('year').valueOf() })
    await this.init()
  }

  getTotalSalary = () => {
    let total = 0
    for (let s of (this.state.reportData || [])) {
      total += s.amount
    }
    return total
  }

  editEmployee = (employeeId) => {
    window.location.href = '/employees/edit/' + employeeId
  }

  render() {
    return (
        <div className="p-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="m-0">Salary Report - {moment(this.state.selectedYear).format('YYYY')}</h3>
                <div className="d-flex align-items-center justify-content-center">
                  <Datetime id="filter-date" value={this.state.selectedYear ? new Date(this.state.selectedYear) : null}
                            closeOnSelect={true} closeOnClickOutside={true} dateFormat="DD-MM-YYYY"
                            onChange={this.onFilter}/>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table mt-4 mb-4">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Salary</th>
                    <th scope="col">Daily Salary</th>
                    <th scope="col">Total Leaves</th>
                    <th scope="col">Total Paid Leaves</th>
                    <th scope="col">Used Paid Leaves</th>
                    <th scope="col">Remaining Paid Leaves</th>
                    <th scope="col">Encash Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.loading ? (
                      <tr>
                        <td colSpan="13" className="text-center">
                          <div className="loader">Loading...</div>
                        </td>
                      </tr>
                  ) : null}
                  {!this.state.loading && this.state.reportData.map((employee, i) => (
                      <tr key={i}>
                        <td onClick={() => this.editEmployee(employee.employeeId)} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                          {employee.name}
                        </td>
                        <td className="no-wrap">&#x20b9; {employee.salary}</td>
                        <td className="no-wrap">&#x20b9; {employee.dailySalary}</td>
                        <td>{employee.totalLeaves}</td>
                        <td>{employee.totalPaidLeaves}</td>
                        <td>{employee.usedPaidLeaves}</td>
                        <td>{employee.remainingPaidLeaves}</td>
                        <td>&#x20b9; {employee.amount}</td>
                      </tr>
                  ))}
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colSpan={7}></td>
                    <td><b>&#x20b9; {this.getTotalSalary()}</b></td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default AnnualLeaveReport
