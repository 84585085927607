import React from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#329599'
  }
})

const InvoiceItemsTable = ({ salarySlip }) => (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader/>
      <InvoiceTableRow salarySlip={salarySlip}/>
      {/*<InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />*/}
      {/*<InvoiceTableFooter items={invoice.items} />*/}
    </View>
)

export default InvoiceItemsTable
