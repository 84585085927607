import React from 'react'
import { signOut } from 'firebase/auth'
import { auth } from './base'
import { useNavigate, Outlet, NavLink } from 'react-router-dom'

const Layout = () => {
  const history = useNavigate()

  const logout = () => {
    signOut(auth)
        .then(() => {
          history('/login')
        })
        .catch((error) => {
          console.log(error)
        })
  }

  return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <NavLink to="/" className="nav-link navbar-brand active">Flex CRM</NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false"
                    aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink to="/employees" className="nav-link active">Employees</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/leaves" className="nav-link active">Leaves</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/salary-report" className="nav-link active">Salary Report</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/annual-leave-report" className="nav-link active">Annual Leave Report</NavLink>
                </li>
              </ul>
              <div className="d-flex">
                <NavLink to="/settings" className="nav-link active">Settings</NavLink>
                <a className="nav-link cursor" onClick={logout}>Logout</a>
              </div>
            </div>
          </div>
        </nav>
        <div className="mt-4 mb-4">
          <Outlet />
        </div>
      </>
  )
}

export default Layout
