const { getDoc, query, doc, updateDoc } = require('firebase/firestore')
const { db } = require('../base')

class UtilsService {
  async getSalaryMonth() {
    let salaryMonth = await getDoc(query(doc(db, 'utils/salary-months')))
    return salaryMonth.data()
  }

  async saveSalaryMonth(salaryMonth) {
    const salarySlipRef = await doc(db, 'utils', 'salary-months')
    await updateDoc(salarySlipRef, salaryMonth)
  }

  formatCurrency(amount) {
    return 'Rs. ' + new Intl.NumberFormat('en-IN').format(amount);
  }
}

export default new UtilsService()
