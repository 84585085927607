import React, { useCallback, useContext } from 'react'
import { AuthContext } from './Auth.js'
import { Navigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from './base'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const history = useNavigate()

  const handleLogin = useCallback(
      async event => {
        event.preventDefault()
        const { email, password } = event.target.elements
        try {
          await signInWithEmailAndPassword(auth, email.value, password.value)
          history('/')
        } catch (error) {
          alert(error)
        }
      },
      [ history ]
  )

  const { currentUser } = useContext(AuthContext)

  if (currentUser) {
    return <Navigate to="/"/>
  }

  return (
      <div className="vh-100 container-fluid">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div className="card" style={{ height: '350px', width: '360px' }}>
            <div className="card-header">
              <h1>Log in</h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email address</label>
                  <input type="email" className="form-control" id="email" aria-describedby="emailHelp"/>
                  <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input type="password" className="form-control" id="password"/>
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Login
